import React, { ComponentType } from 'react'
import { NewsItemHorizontal, NewsItemVertical } from './NewsList'
import { News } from 'types'
import { newsTagsPath } from 'shared/urlHelpers'
import LinkButton from '@/components/router/LinkButton'
import HighlightedItem from './HighlightedItem'

export type NewsBoxProps = {
  news: News[]
  highlight: string
  fast?: boolean
  showMoreButton?: boolean
  tagSlugs: string
  filteredView?: boolean
  videoTeaser?: boolean
  priority?: boolean
  additionalButtons?: NewsBoxAdditionalButton[]
}

interface NewsBoxAdditionalButton {
  text: string
  url: string
  disableClass?: boolean
}

// TODO LINK TO DETAIL VIEW, if condition for more news items output
export const NewsBox: ComponentType<NewsBoxProps> = ({
  news,
  highlight,
  fast = true,
  showMoreButton = true,
  tagSlugs,
  additionalButtons = null,
  filteredView = false,
  videoTeaser = false,
  priority
}) => {
  if (!news.length) return null
  const showedItems = fast ? news : news.slice(0, 4)
  const highlightedItem =
    showedItems.filter((n) =>
      n.tags.map((t) => t.name).includes(highlight)
    )[0] || showedItems[0]
  const otherNews = showedItems.filter((n) => n.id !== highlightedItem.id)
  return (
    <div className="view-vertical">
      <HighlightedItem
        item={highlightedItem}
        video={videoTeaser}
        priority={priority}
      />
      {otherNews.length > 0 && !filteredView && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 auto-rows-auto gap-8 mb-8">
          {otherNews.map((on) => (
            <NewsItemVertical
              key={on.id}
              news={on}
              withMeta={false}
              priority={priority}
            />
          ))}
        </div>
      )}
      {otherNews.length > 0 && filteredView && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 auto-rows-auto gap-8 mb-8">
          {otherNews.map((on) => (
            <NewsItemHorizontal
              key={on.id}
              news={on}
              withMeta={false}
              video={videoTeaser}
              priority={priority}
            />
          ))}
        </div>
      )}
      {(showMoreButton ||
        (additionalButtons && additionalButtons?.length > 0)) && (
        <div className="flex flex-col sm:flex-row justify-center gap-2">
          {showMoreButton && (
            <LinkButton
              href={newsTagsPath(tagSlugs)}
              className="w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3"
              buttonClassName="w-full"
            >
              weitere News
            </LinkButton>
          )}
          {additionalButtons?.map((button, i) => {
            return (
              <LinkButton
                key={i}
                href={button.url}
                className={
                  !button.disableClass
                    ? `w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/3`
                    : ``
                }
                buttonClassName="w-full"
              >
                {button.text}
              </LinkButton>
            )
          })}
        </div>
      )}
    </div>
  )
}
